'use client'; // Error components must be Client Components

import { NOT_FOUND_PAGE } from '@/common/constant';
import { useEffect } from 'react';

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error('Error ', error);
        // window.location.href = 'https://common.hkjc.com/maint/generic.html';
        window.location.href = NOT_FOUND_PAGE;
    }, [error]);

    return (
        <div>
            {/* <h2>Something went wrong!</h2>
            <button
                onClick={
                    // Attempt to recover by trying to re-render the segment
                    () => reset()
                }
            >
                Try again
            </button> */}
        </div>
    );
}
